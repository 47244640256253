var arrows = {
	prev: '<button class="arrow-left"><svg><title>Previous</title><use xlink:href="' + patch.stylesheet_directory_uri + '/assets/img/icons.svg#arrow-box"></use></svg></button>',
	next: '<button class="arrow-right"><svg><title>Next</title><use xlink:href="' + patch.stylesheet_directory_uri + '/assets/img/icons.svg#arrow-box"></use></svg></button>',
	prevGallery: '<button class="arrow-left arrow-gallery"><svg><title>Previous</title><use xlink:href="' + patch.stylesheet_directory_uri + '/assets/img/icons.svg#arrow1"></use></button>',
	nextGallery: '<button class="arrow-right arrow-gallery"><svg><title>Next</title><use xlink:href="' + patch.stylesheet_directory_uri + '/assets/img/icons.svg#arrow1"></use></svg></button>',
	prevSimple: '<button class="arrow-left arrow-simple"><svg><title>Previous</title><use xlink:href="' + patch.stylesheet_directory_uri + '/assets/img/icons.svg#arrow1"></use></svg></button>',
	nextSimple: '<button class="arrow-right arrow-simple"><svg><title>Next</title><use xlink:href="' + patch.stylesheet_directory_uri + '/assets/img/icons.svg#arrow1"></use></svg></button>',
};

document.addEventListener('DOMContentLoaded', function() {

	svg4everybody();

});
