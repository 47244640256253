document.addEventListener('DOMContentLoaded', function() {

	/**
	 * Cache and declare variables
	 */
	var header = document.getElementById('Header'),
		docTop = document.scrollTop,
		lastTop = docTop,
		scrollParent = document.documentElement.scrollTop ? document.documentElement : document.body;

	/**
	 * Checks the scroll position to see if the header should be fixed or not
	 */
	function headerScrollHandler() {
		docTop = scrollParent.scrollTop;

		// Sticky header on scroll
		if (200 < docTop) {
			//header.classList.add('Header--stuck');
		} else {
			//header.classList.remove('Header--stuck');
		}

		lastTop = docTop;
	}
	headerScrollHandler();
	window.addEventListener('scroll', function() {
		waitForFinalEvent(headerScrollHandler, timeToWaitForLast, 'headerScroll');
	}, false);

});
