document.addEventListener('DOMContentLoaded', function() {

	/**
	 * Cache and declare variables
	 */
	var topLink = document.getElementById('PagetopNav'),
		docTop = document.scrollTop,
		lastTop = docTop,
		scrollParent = document.documentElement.scrollTop ? document.documentElement : document.body;

	/**
	 * Checks the scroll position to make adjustments, show/hide
	 */
	if (topLink) {
		topLink.classList.add('PagetopNav--hide');
		jQuery(topLink).on('click', function(e) {
			e.preventDefault();
			jQuery('html, body').animate({scrollTop: 0});
			history.pushState(null, document.title, window.location.pathname + window.location.search);
		});
	}
	function scrollHandler() {
		docTop = scrollParent.scrollTop;
		if (200 < docTop) {
			topLink.classList.remove('PagetopNav--hide');
		} else {
			topLink.classList.add('PagetopNav--hide');
		}
		lastTop = docTop;
	}
	scrollHandler();
	window.addEventListener('scroll', function() {
		waitForFinalEvent(scrollHandler, timeToWaitForLast, 'topNav');
	}, false);

});
