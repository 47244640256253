document.addEventListener('DOMContentLoaded', function() {

	jQuery('.Testimonials-slides').slick({
		arrows: true,
		autoplay: false,
		dots: false,
		draggable: true,
		fade: true,
		infinite: true,
		nextArrow: arrows.nextSimple,
		prevArrow: arrows.prevSimple,
		slidesToScroll: 1,
		slidesToShow: 1,
		speed: 600,
		swipe: true,
		touchMove: true,
	});

});
