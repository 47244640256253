var Announcement = {
	cookieName: 'announce',

	init: function() {
		var containers = document.getElementsByClassName('Announcement'),
			link = null;
		if (containers) {
			for (var i = 0, l = containers.length; i < l; i++) {
				link = containers[i].querySelector('.Close');
				this.addClose(link, containers[i]);
				this.maybeClose(containers[i]);
			}
		}
	},

	addClose: function(link, container) {
		if (link && container) {
			link.addEventListener('click', function(e) {
				e.preventDefault();
				var name = container.getAttribute('data-name');
				docCookies.setItem(Announcement.cookieName, name);
				container.classList.add('is-closed');
			});
		}
	},

	maybeClose: function(container) {
		var current = docCookies.getItem(this.cookieName);
		if (container && current) {
			var name = container.getAttribute('data-name');
			if (name === current) {
				container.classList.add('is-closed');
			}
		}
	},
};

document.addEventListener('DOMContentLoaded', function() {

	Announcement.init();

});
