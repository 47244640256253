document.addEventListener('DOMContentLoaded', function() {

	jQuery('.Banner--slides').each(function() {
		var autoplaySetting = ( jQuery(this).hasClass('Banner--autoplay') ? true : false );
		jQuery('.Banner-slides', this).slick({
			arrows: true,
			autoplay: autoplaySetting,
			autoplaySpeed: 7000,
			dots: false,
			draggable: true,
			fade: true,
			infinite: true,
			nextArrow: arrows.next,
			prevArrow: arrows.prev,
			slidesToScroll: 1,
			slidesToShow: 1,
			speed: 600,
			swipe: true,
			touchMove: true,
		});
	});

});
