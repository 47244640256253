document.addEventListener('DOMContentLoaded', function() {

	jQuery('.PagestartNav').on('click', function(e) {
		e.preventDefault();
		var target = this.getAttribute('href');
		if (target) {
			$('html, body').animate({ scrollTop: jQuery(target).offset().top });
		}
	});

});
