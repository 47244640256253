document.addEventListener('DOMContentLoaded', function() {

	var navContainer = document.getElementById('NavContainer'),
		searchLink = document.querySelector('.SearchLink a'),
		searchClose = navContainer.querySelector('.Search .Close');
	if (searchLink) {
		searchLink.addEventListener('click', function(e) {
			e.preventDefault();
			navContainer.classList.toggle('is-searchActive');
		});

		if (searchClose) {
			searchClose.addEventListener('click', function(e) {
				e.preventDefault();
				navContainer.classList.remove('is-searchActive');
			});
		}
	}

});
