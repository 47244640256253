document.addEventListener('DOMContentLoaded', function() {

	jQuery('.PageNav-list a').on('click', function(e) {
		e.preventDefault();
		var target = this.getAttribute('href');
		if (target) {
			$('html, body').animate({scrollTop: jQuery(target).offset().top});
			history.pushState(null, document.title, target);
		}
	});

	/**
	 * Detect scroll position and add sticky class to body
	 */
	// get initial offset height of stickyTrigger
	var elementOffset = null;
	if (jQuery('.PageNav').length) {
		elementOffset = jQuery('.PageNav').offset().top;
	}
	
	// NEED TO UPDATE timeToWaitForLast based on Jake's function
	window.onscroll = function() {
		var windowTop = $(window).scrollTop();
	
		// checks for #stickyTrigger ID and will trigger "sticky"
		if (elementOffset) {
			var distance = (elementOffset - windowTop - 0); // fixed header offset
			if (distance < 0) {
				jQuery('body').addClass('is-stickyNav');
			} else {
				jQuery('body').removeClass('is-stickyNav');
			}
		}
	};

});
